<template>
  <div class="popup" :class="'popup--color-'+color">
    <div class="popup__window">
        <div class="popup__inner">
            <div class="popup__close-btn"></div>
            <slot></slot>
        </div>
    </div>
    <div class="popup__overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
      color: String
  }
}
</script>