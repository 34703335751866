<template>

    <div v-if="isLoading" class="loading-screen">Loading...</div>

    <template v-else>

      <Scene backgroundImage="./assets/arla_overview_loop_v1.jpeg" backgroundVideo="./assets/arla_overview_loop_v1.m4v">
      </Scene>

      <Popup :color="popupColor">
        
        <template v-if="currentScreen === Screen.Home">

          <div class="layout layout--home">

            <div class="layout__left">
              <h1 class="heading-1">WHICH CONSUMER ARE YOU?</h1>
              <div class="rich-text">Maybe you already know, if you belong to the Performance, Active or Lifestyle nutrition segment. If not, now is your chance to find out.</div>
              <div class="progress-info u-only-desktop">8 Questions</div>
            </div>

            <div class="layout__right">
              <div class="avatars avatars--home">
                <PreloadedImage class="avatars__image" src="./assets/home-avatars.png"/>
                <div class="large-button" @click="currentScreen = Screen.Question1">Start<br/>the<br/>test</div>
              </div>
              <div class="progress-info u-only-mobile">8 Questions</div>
            </div>

          </div>


        </template>

        <template v-else-if="currentScreen === Screen.Question1">

          <div class="layout layout--quiz">

            <div class="layout__left">
              <h2 class="heading-2">How often do you choose foods/beverages to improve athletic performance?</h2>
              <div class="progress-info u-only-desktop">1/8</div>
            </div>
            
            <div class="layout__right">
              <Choices :options="question1Options" :selectedOption="question1Answer" @selected="(answer)=>{question1Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question1Answer === null}" @click="currentScreen = Screen.Question2"></div>
              <div class="progress-info u-only-mobile">1/8</div>
            </div>

          </div>

        </template>

        <template v-else-if="currentScreen === Screen.Question2">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How often do you exercise strenuously (increase heart rate for 30 minutes or more)?</h2>
              <div class="progress-info u-only-desktop">2/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question2Options" :selectedOption="question2Answer" @selected="(answer)=>{question2Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question2Answer === null}" @click="currentScreen = Screen.Question3"></div>
              <div class="progress-info u-only-mobile">2/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question3">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How important is regular exercise/active lifestyle in contributing to your overall health?</h2>
              <div class="progress-info u-only-desktop">3/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question3Options" :selectedOption="question3Answer" @selected="(answer)=>{question3Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question3Answer === null}" @click="currentScreen = Screen.Question4"></div>
              <div class="progress-info u-only-mobile">3/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question4">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How effective is regular exercise/active lifestyle in contributing to your overall health?</h2>
              <div class="progress-info u-only-desktop">4/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question4Options" :selectedOption="question4Answer" @selected="(answer)=>{question4Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question4Answer === null}" @click="currentScreen = Screen.Question5"></div>
              <div class="progress-info u-only-mobile">4/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question5">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How often do you exercise moderately (increase heart rate for 30 minutes or more)?</h2>
              <div class="progress-info u-only-desktop">5/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question5Options" :selectedOption="question5Answer" @selected="(answer)=>{question5Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question5Answer === null}" @click="currentScreen = Screen.Question6"></div>
              <div class="progress-info u-only-mobile">5/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question6">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How often do you select foods for healthful reasons?</h2>
              <div class="progress-info u-only-desktop">6/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question6Options" :selectedOption="question6Answer" @selected="(answer)=>{question6Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question6Answer === null}" @click="currentScreen = Screen.Question7"></div>
              <div class="progress-info u-only-mobile">6/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question7">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">How often do you select beverages for healthful reasons?</h2>
              <div class="progress-info u-only-desktop">7/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question7Options" :selectedOption="question7Answer" @selected="(answer)=>{question7Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question7Answer === null}" @click="currentScreen = Screen.Question8"></div>
              <div class="progress-info u-only-mobile">7/8</div>
            </div>
          </div>
        </template>

        <template v-else-if="currentScreen === Screen.Question8">
          <div class="layout layout--quiz">
            <div class="layout__left">
              <h2 class="heading-2">Please select your level of agreement with the statement “I go out of my way to learn about nutrition and its impact on health”.</h2>
              <div class="progress-info u-only-desktop">8/8</div>
            </div>
            <div class="layout__right">
              <Choices :options="question8Options" :selectedOption="question8Answer" @selected="(answer)=>{question8Answer = answer}"/>
              <div class="quiz-instruction">Choose an answer above</div>
              <div class="button" :class="{'button--disabled': question8Answer === null}" @click="currentScreen = Screen.Result"></div>
              <div class="progress-info u-only-mobile">8/8</div>
            </div>
          </div>
        </template>
        
        <template v-else-if="currentScreen === Screen.Result">

          <template v-if="segmentResult === 'Enthusiasts'">
            <div class="layout layout--result">
              <div class="layout__left">
                <h1 class="heading-1 heading-1--red">YOU ARE A <span>PERFORMANCE NUTRITION CONSUMER</span></h1>
                <div class="avatars avatars--enthusiast u-only-mobile">
                  <PreloadedImage class="avatars__image" src="./assets/enthusiasts-avatars.png"/>
                  <div class="button button--red button--restart-quiz" @click="resetQuiz"></div>
                </div>
                <div class="rich-text">
                  <div class="heading-3 heading-3--red">The typical Performance nutrition consumer</div>
                  <p>The Performance nutrition segment exercises strenuously three times a week or more, and they often combine their workout with socialising.</p> 
                  <p>They are in control of the family's diet, and choose food and beverages for health reasons. They are not prone to being overweight. They are typically price-conscious and receptive to processed food. Besides, they are willing to sacrifice taste and convenience to achieve a healthy lifestyle.</p>
                  <p>They are to be considered as early adopters.</p>
                  <div class="percentage-blob percentage-blob--red">
                      <div class="percentage-blob__value">17</div>
                      <div class="percentage-blob__symbol">%</div>
                      <div class="percentage-blob__text percentage-blob__text--right-align">Of the<br/>global<br/>population</div>
                  </div>
                  <p>18-39 years old<br/>
                    Lives with spouse/partner<br/>
                    Household with children</p>
                </div>
              </div>
              <div class="layout__right">
                <div class="avatars avatars--enthusiast">
                  <PreloadedImage class="avatars__image u-only-desktop" src="./assets/enthusiasts-avatars.png"/>
                  <div class="button button--red button--restart-quiz u-only-desktop-flex" @click="resetQuiz"></div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="segmentResult === 'Easy Health'">
            <div class="layout layout--result">
              <div class="layout__left">
                <h1 class="heading-1 heading-1--blue">YOU ARE AN<br/><span>ACTIVE NUTRITION CONSUMER</span></h1>
                <div class="avatars avatars--easy-health u-only-mobile">
                  <PreloadedImage class="avatars__image" src="./assets/easy-health-avatars.png"/>
                  <div class="button button--blue button--blue button--restart-quiz" @click="resetQuiz"></div>
                </div>
                <div class="rich-text">
                  <div class="heading-3 heading-3--blue">The typical Active nutrition consumer</div>
                  <p>The Active nutrition segment exercises moderately three times a week or more. They believe in the importance of regular exercise and an active lifestyle for overall health.</p>
                  <p>When it comes to food, they are less receptive to processed food, and less prone to being overweight than the average consumer. They are willing to pay a premium for health, and choose food and beverages for health reasons.</p>
                  <p>The Active nutrition consumers are the least likely of the three segments to adopt and try out new products.</p>
                  <div class="percentage-blob percentage-blob--blue">
                      <div class="percentage-blob__value">18</div>
                      <div class="percentage-blob__symbol">%</div>
                      <div class="percentage-blob__text percentage-blob__text--right-align">Of the<br/>global<br/>population</div>
                  </div>
                  <p>40+ years old<br/>
                    Lives with spouse/partner<br/>
                    Household without children</p>
                </div>
              </div>
              <div class="layout__right">
                <div class="avatars avatars--easy-health">
                  <PreloadedImage class="avatars__image u-only-desktop" src="./assets/easy-health-avatars.png"/>
                  <div class="button button--blue button--restart-quiz u-only-desktop-flex" @click="resetQuiz"></div>
                </div>
              </div>
            </div>
        
          </template>

          <template v-if="segmentResult === 'Healthy Feel Goods'">
            <div class="layout layout--result">
              <div class="layout__left">
                <h1 class="heading-1 heading-1--yellow">YOU ARE A <span>LIFESTYLE NUTRITION CONSUMER</span></h1>
                <div class="avatars avatars--healthy-feel-goods u-only-mobile">
                  <PreloadedImage class="avatars__image" src="./assets/healthy-feel-good-avatars.png"/>
                  <div class="button button--yellow button--restart-quiz" @click="resetQuiz"></div>
                </div>
                <div class="rich-text">
                  <div class="heading-3 heading-3--yellow">The typical Lifestyle nutrition consumer</div>
                  <p>Lifestyle nutrition consumers don't exercise regularly, but the feeling of general well-being is driving this segment to have healthy habits.</p>
                  <p>Lifestyle nutrition consumers actively select food and beverages for health reasons and are proactive in learning about nutrition and its impact on health - and they are willing to pay a premium for health benefits. They are typically in control of the family's diet, and are receptive to processed food. They use dietary regimes to stay healthy and feel guilty about eating something unhealthy.</p>
                  <p>They are early adopters.</p>
                  <div class="percentage-blob percentage-blob--yellow">
                      <div class="percentage-blob__value">36</div>
                      <div class="percentage-blob__symbol">%</div>
                      <div class="percentage-blob__text percentage-blob__text--right-align">Of the<br/>global<br/>population</div>
                  </div>              
                  <p>18-39 years old<br/>
                    Lives with spouse/partner<br/>
                    Household with children</p>
                </div>
              </div>
              <div class="layout__right">
                <div class="avatars avatars--healthy-feel-goods">
                  <PreloadedImage class="avatars__image u-only-desktop" src="./assets/healthy-feel-good-avatars.png"/>
                  <div class="button button--yellow button--restart-quiz u-only-desktop-flex" @click="resetQuiz"></div>
                </div>
              </div>
            </div>
            
          </template>

          <template v-if="segmentResult === 'Unclassified'">
            <div class="layout layout--result">
              <div class="layout__left">
                <h1 class="heading-1 heading-1--grey">NO MATCH</h1>
                <div class="avatars avatars--no-match u-only-mobile">
                  <PreloadedImage class="avatars__image" src="./assets/no-match-avatars.png"/>
                  <div class="button button--restart-quiz" @click="resetQuiz"></div>
                </div>
                <div class="rich-text">Your profile is not matching any of the three mainstream consumer segments. You would be classified as “all other” in the survey.</div>
              </div>
              <div class="layout__right">
                <div class="avatars avatars--no-match">
                  <PreloadedImage class="avatars__image u-only-desktop" src="./assets/no-match-avatars.png"/>
                  <div class="button button--restart-quiz u-only-desktop-flex" @click="resetQuiz"></div>
                </div>
              </div>
            </div>
            
          </template>


        </template>


    </Popup>

    </template>

</template>

<script>
import Scene from '@/components/Scene';
import Popup from '@/components/Popup';
import Choices from '@/components/Choices';
import {preloadImages} from '@/helpers/assetHelper'
import PreloadedImage from './PreloadedImage.vue';

function getSegment($B10, $B12, $B14, $B16, $B18, $B20, $B22, $B24)
{
  return (($B10>=4 && $B12>=5)?"Enthusiasts":((($B14>=4||$B16>=4)&& $B18>=5)?"Easy Health":((($B20>=4&& $B22>=4)||$B24>=4)?"Healthy Feel Goods":"Unclassified")))
}

const Screen = {
  Home: 'Home',
  Question1: 'Question1',
  Question2: 'Question2',
  Question3: 'Question3',
  Question4: 'Question4',
  Question5: 'Question5',
  Question6: 'Question6',
  Question7: 'Question7',
  Question8: 'Question8',
  Result: 'Result'
};

export default {
  name: 'Main',
  components: {Scene, Popup, Choices, PreloadedImage},
  props: {},
  data()
  {
    return {
      isLoading: false,
      Screen: Screen,
      currentScreen: Screen.Home, 

      question1Answer: null,
      question2Answer: null,
      question3Answer: null,
      question4Answer: null,
      question5Answer: null,
      question6Answer: null,
      question7Answer: null,
      question8Answer: null,

      question1Options: [
        {value: 5, text: 'Always'},
        {value: 4, text: 'Usually'},
        {value: 3, text: 'Sometimes'},
        {value: 2, text: 'Rarely'},
        {value: 1, text: 'Never'}
      ],

      question2Options: [
        {value: 6, text: 'Daily'},
        {value: 5, text: '3 times a week or more'},
        {value: 4, text: 'Once or twice a week'},
        {value: 3, text: 'Once or twice every 2 weeks'},
        {value: 2, text: 'Once a month or less'},
        {value: 1, text: 'Never'}
      ],

      question3Options: [
        {value: 5, text: 'Extremely important'},
        {value: 4, text: 'Very important'},
        {value: 3, text: 'Important'},
        {value: 2, text: 'Somewhat important'},
        {value: 1, text: 'Not important'}
      ],

      question4Options: [
        {value: 5, text: 'Extremely effective'},
        {value: 4, text: 'Very effective'},
        {value: 3, text: 'Effective'},
        {value: 2, text: 'Somewhat effective'},
        {value: 1, text: 'Not effective'}
      ],

      question5Options: [
        {value: 6, text: 'Daily'},
        {value: 5, text: '3 times a week or more'},
        {value: 4, text: 'Once or twice a week'},
        {value: 3, text: 'Once or twice every 2 weeks'},
        {value: 2, text: 'Once a month or less'},
        {value: 1, text: 'Never'}
      ],

      question6Options: [
        {value: 5, text: 'Always'},
        {value: 4, text: 'Usually'},
        {value: 3, text: 'Sometimes'},
        {value: 2, text: 'Rarely'},
        {value: 1, text: 'Never'}
      ],

      question7Options: [
        {value: 5, text: 'Always'},
        {value: 4, text: 'Usually'},
        {value: 3, text: 'Sometimes'},
        {value: 2, text: 'Rarely'},
        {value: 1, text: 'Never'}
      ],

      question8Options: [
        {value: 5, text: 'Strongly agree'},
        {value: 4, text: 'Agree'},
        {value: 3, text: 'Neither agree nor disagree'},
        {value: 2, text: 'Disagree'},
        {value: 1, text: 'Strongly disagree'}
      ]
    }
  },
  computed:
  {
    popupColor()
    {
      var result = 'green';

      if (this.currentScreen === Screen.Result && this.segmentResult === 'Enthusiasts')
      {
        result = 'red';
      }
      else if (this.currentScreen === Screen.Result && this.segmentResult === 'Easy Health')
      {
        result = 'blue';
      }
      else if (this.currentScreen === Screen.Result && this.segmentResult === 'Healthy Feel Goods')
      {
        result = 'yellow';
      }
       else if (this.currentScreen === Screen.Result && this.segmentResult === 'Unclassified')
      {
        result = 'grey';
      }
      
      return result;
    },
    segmentResult()
    {
      return getSegment(
        this.question1Answer.value,
        this.question2Answer.value,
        this.question3Answer.value,
        this.question4Answer.value,
        this.question5Answer.value,
        this.question6Answer.value,
        this.question7Answer.value,
        this.question8Answer.value
      );
    },
  },
  created()
  {
    this.isLoading = true;

    var preloadImagesPromise = preloadImages([
        './assets/arla_overview_loop_v1.jpeg',
        './assets/arrow.svg',
        './assets/easy-health-avatars.png',
        './assets/enthusiasts-avatars.png',
        './assets/healthy-feel-good-avatars.png',
        './assets/home-avatars.png',
        './assets/no-match-avatars.png',
        './assets/reset-arrow.svg',
        './assets/checkmark.svg'
      ], false);

    // NOTE: Add a delay so the loading screen dosent appear just for a few frames. That looks super buggy.
    var delayPromise = new Promise((resolve/*, reject*/)=>{
        setTimeout(() => {
            resolve();
        }, 500);
      });

    Promise.all([preloadImagesPromise, delayPromise])
      .then(()=>{
        this.isLoading = false;
      });

  },
  methods:
  {
    resetQuiz()
    {
        this.question1Answer = null;
        this.question2Answer = null;
        this.question3Answer = null;
        this.question4Answer = null;
        this.question5Answer = null;
        this.question6Answer = null;
        this.question7Answer = null;
        this.question8Answer = null;

        this.currentScreen = Screen.Home;
    }
  }
}
</script>
