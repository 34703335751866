<template>
    <div class="choices">
        <div class="choices__choice" :class="{'choices__choice--selected': option === selectedOption}" v-for="option in options" :key="option.value" @click="$emit('selected', option)">
            <span>{{option.text}}</span>
        </div>
    </div>
</template>


<script>

export default {
  name: 'Scene',
  props: {
      options: Array,
      selectedOption: Object
  }
}
</script>
